@import 'styles/variables.scss';

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInAndMove {
  0% { opacity: 0; transform: translate(0, 20px);}
  100% { opacity: 1; transform: translate(0, 0);}
}

@keyframes increaseWidth {
  0% { transform: scale3d(0, 1, 1); }
  100% { transform: scale3d(1, 1, 1); }
}

@keyframes moveRight {
  0% { transform: translate(-110%, 0); }
  100% { transform: translate(0, 0); }
}

.header {
  position: relative;
  display: grid;
  grid-template-columns: minmax(28px, 1fr) minmax(auto, 1200px) minmax(28px, 1fr);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 94px;
  background-image: url("./img/bg-intro.jpg");
  background-color: $main-blue-color;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 800;
}

.logo {
  display: inline-block;
  line-height: 0;
  animation: fadeIn 0.6s ease-in-out 4.2s both;

  svg {
    display: inline-block;
    line-height: 0;
  }
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 94px;
  margin: 0 auto;
}

.rightSide {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  padding-left: 20px;
  padding-right: 40px;
  animation: fadeIn 0.6s ease-in-out 4.2s both;
}

.menuItem {
  position: relative;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  margin-right: 60px;

  &:hover {
    &:after {
      transform: scale3d(1, 1, 1);
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -8px;
    background-color: $main-blue-color;
    height: 2px;
    width: 100%;
    transform: scale3d(0, 1, 1);
    transition: all 0.2s ease-in-out;
  }
}

.lang {
  display: flex;
}

.langBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  transition: all 0.14s ease-in-out;
  mix-blend-mode: screen;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    color: $main-blue-color;
    border: 1px solid $main-blue-color;
  }

  &:focus {
    outline: none;
  }

  &:first-child {
    animation: fadeIn 0.5s ease-in-out 5.5s both;
  }

  &:last-child {
    animation: fadeIn 0.5s ease-in-out 5.2s both;
  }
}

.langBtnActive {
  font-weight: bold;
  color: #121E2E;
  background-color: #fff;

  &:hover {
    color: #121E2E;
    background-color: #fff;
    border: 1px solid #fff;
  }
}

.blueLine {
  display: flex;
  align-items: center;
}

.blueLineItem {
  width: 100%;
  height: 3px;
  background-color: $main-blue-color;
  animation: increaseWidth 0.6s ease-in-out 4.6s both;
  transform-origin: right;
}

.mobileMenuBtn {
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;

  .mobileMenuBtnItem {
    position: absolute;
    width: 40px;
    height: 2px;
    background-color: #fff;
    transform-origin: center;
    transition: transform 0.3s ease-in-out;

    &:nth-child(1) {
      transform: translate(0, -14px);
    }

    &:nth-child(2) {
      transform: translate(0, 0);
    }

    &:nth-child(3) {
      transform: translate(0, 14px);
    }
  }
}

.mobileMenuBtnActive {
  .mobileMenuBtnItem {
    &:nth-child(1) {
      transform: translate(0, 0px) rotate(45deg);
    }

    &:nth-child(2) {
      transform: translate(0, 0px) rotate(135deg);
    }

    &:nth-child(3) {
      transform: translate(0, 0px) rotate(-45deg);
    }
  }

  .mobileMenuBtnConvas {
    display: block;
  }
}

.mobileMenuBtnConvas {
  display: none;
  position: fixed;
  top: 74px;
  right: 0;
  height: calc(100% - 74px);
  width: 90px;
  z-index: 900;
}

@media only screen and (max-width: 910px){
  .menu {
    padding-right: 15px;
  }

  .menuItem {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 768px){
  .header {
    background-image: none;
    height: 74px;
  }

  .content {
    height: 74px;
  }

  .logo {
    animation: none;

    svg {
      width: 110px;
    }
  }

  .mobileMenuBtn {
    display: flex;
  }

  .rightSide {
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 90px);
    height: calc(100% - 80px);
    top: 80px;
    left: 0;
    background-color: $main-blue-color;
    animation: moveRight 0.6s ease-in-out both;

    & > nav {
      width: 100%;
    }
  }

  .mobile {
    display: flex;
  }

  .menu {
    display: block;
    width: 100%;
    animation: none;
    padding: 20px 0 0;
  }

  .menuItem {
    position: relative;
    font-size: 22px;
    line-height: 26px;
    padding-top: 20px;
    padding-bottom: 8px;
    margin-left: 28px;
    margin-right: 28px;

    &:nth-child(1) {
      animation: fadeInAndMove 0.3s linear 0.6s both;

      &:after {
        animation: increaseWidth 0.3s linear 1.2s both;
      }
    }

    &:nth-child(2) {
      animation: fadeInAndMove 0.3s linear 0.7s both;

      &:after {
        animation: increaseWidth 0.3s linear 1.3s both;
      }
    }

    &:nth-child(3) {
      animation: fadeInAndMove 0.3s linear 0.8s both;

      &:after {
        animation: increaseWidth 0.3s linear 1.4s both;
      }
    }

    &:after {
      background-color: #fff;
      bottom: 0;
      height: 1px;
      transform-origin: left;
    }
  }

  .lang {
    padding: 28px;
  }

  .langBtn {
    margin-right: 15px;

    &:first-child {
      animation-delay: 1.7s;
      animation-duration: 0.3s;
    }

    &:last-child {
      animation-delay: 1.9s;
      animation-duration: 0.3s;
    }
  }

  .blueLineItem {
    display: none;
  }
}
