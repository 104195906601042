@import 'styles/variables.scss';

.buttonWrap {
  position: fixed;
  bottom: 10%;
  right: 40px;
  z-index: 800;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $main-blue-color;
  box-shadow: 1px 4px 12px rgba(98, 109, 149, 0.42);
  cursor: pointer;

  &:hover {
    opacity: 1 !important;
    background-color: $secondary-blue-color;
  }
}

@media only screen and (max-width: 768px){
  .buttonWrap {
    display: none;
  }
}
