@import 'styles/variables.scss';

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeInAndMove {
  0% {opacity: 0; transform: translate(0, 10px);}
  100% {opacity: 1; transform: translate(0, 0px);}
}

.containerWrap {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out 0.3s;
  z-index: 999;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background-color: rgba(16, 24, 35, 0.7);
  animation: fadeIn 0.4s ease-in-out both;
}

.popupWrap {
  width: 100%;
  max-width: 480px;
  transition: all 0.3s ease-in-out;
}

.popup {
  position: relative;
  background-color: #fff;
  padding: 52px 30px 40px;
  min-width: 320px;
  border-radius: 2px;
  animation: fadeIn 0.4s ease-in-out 0.3s both;
}

.form{
  position: relative;
  margin: 0;
}

.disableForm{
  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    animation: fadeIn 0.6s ease-in-out both;
    z-index: 4;
  }
}

.closePopupBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
  background: none;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 4px solid #fff;
    opacity: 0;
    transition: all 0.3s linear;
  }

  svg {
    width: 30px;
    height: 30px;
    transform-origin: center;
    transition: transform 0.3s ease-in-out;

    line {
      transition: stroke 0.3s ease-in-out;
    }
  }

  &:hover {
    &:after {
      opacity: 1;
    }

    svg{
      transform: rotate(90deg);

      line {
        stroke: $secondary-blue-color;
      }
    }
  }
}

.caption {
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  font-family: "Vollkorn";
  font-weight: normal;
  animation: fadeInAndMove 0.3s ease-in-out 0.7s both;
  margin: 0;
}

.desc {
  max-width: 285px;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  animation: fadeInAndMove 0.3s ease-in-out 0.8s both;
  margin: 24px auto 0;
}

.field1 {
  animation: fadeInAndMove 0.3s ease-in-out 0.9s both;
}

.field2 {
  animation: fadeInAndMove 0.3s ease-in-out 1s both;
}

.field3 {
  animation: fadeInAndMove 0.3s ease-in-out 1.1s both;
}

.input,
.textarea {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid #D5D5D5;
  padding: 8px 23px 8px 11px;

  &:focus {
    border: 1px solid $main-blue-color;
  }
}

.input {
  height: 40px;
}

.textarea {
  height: 138px;
}

.label {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.recaptcha{
  position: relative;
  display: flex;
  justify-content: center;
  animation: fadeInAndMove 0.3s ease-in-out 1.2s both;
  margin-top: 20px;

  iframe{
    overflow: hidden;
  }

  &:before{
    content: '';
    display: block;
    position: absolute;
    opacity: 0;
    width: 304px;
    border-bottom: 3px solid red;
    height: 0;
    bottom: 0;
    transition: opacity 1s ease-in-out;
  }
}

.fieldMissed{
  &:before{
    opacity: 1;
  }
}

.acceptBtnWrap {
  text-align: center;
  animation: fadeInAndMove 0.3s ease-in-out 1.3s both;
}

.acceptBtn {
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #fff;
  background-color: #D5D5D5;
  box-shadow: 4px 4px 19px rgba(0, 209, 255, 0);
  border-radius: 2px;
  border: none;
  transition: all 0.2s ease-in-out;
  padding: 20px 70px 19px;
  margin-top: 40px;
  cursor: pointer;

  span {
    display: inline-block;
    transition: transform 0.2s ease-in-out;
  }
}

.active {
  box-shadow: 4px 4px 19px rgba(0, 209, 255, 0.27);
  background-color: $main-blue-color;

  &:hover {
    background-color: $secondary-blue-color;

    span {
      transform: scale(1.1);
    }
  }
}

.successMessage {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: -30px;
  width: calc(100% + 60px);
  height: 100%;
  font-size: 26px;
  line-height: 30px;
  background-color: #fff;
  padding: 0 28px;
  animation: fadeIn 0.4s ease-in-out 2s both;
}

.fade {
  opacity: 0;

  .popupWrap {
    opacity: 0;
  }
}

.hide {
  display: none;
}
