@import 'styles/variables.scss';

@keyframes bounce {
  0% {transform: rotate(0deg);}
  25% {transform: rotate(18deg);}
  50% {transform: rotate(-18deg);}
  75% {transform: rotate(14deg);}
  100% {transform: rotate(0deg);}
}

.footer {
  text-align: center;
  color: #fff;
  background-image: url("./img/earth.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 90px 0 38px;
}

.desc {
  padding: 0 28px;
  margin: 60px 0;
}

.btn {
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #fff;
  background-color: $main-blue-color;
  box-shadow: 4px 4px 19px rgba(0, 209, 255, 0.27);
  border-radius: 2px;
  border: none;
  transition: all 0.2s ease-in-out;
  padding: 30px 66px 29px;
  cursor: pointer;

  &:hover {
    background-color: $secondary-blue-color;
  }
}

.contactsWrap {
  display: grid;
  grid-template-columns: minmax(28px, 1fr) minmax(auto, 1200px) minmax(28px, 1fr);
  grid-template-rows: auto auto;
  margin-top: 140px;
}

.blueLine1 {
  padding-top: 14px;
}

.blueLineItem {
  width: 100%;
  height: 3px;
  background-color: $main-blue-color;
}

.contactsCaption {
  font-size: 26px;
  line-height: 31px;
  text-align: left;
  margin: 0 0 40px 12px;
}

.blueLine2 {
  padding-top: 19px;
}

.social {
  display: flex;
}

.socialLink {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;

  &:first-child {
    animation: fadeIn 0.5s ease-in-out 6.5s both;
  }

  &:last-child {
    animation: fadeIn 0.5s ease-in-out 6.9s both;
  }
}

.socialIcon {
  display: block;

  &:hover path {
    fill: $secondary-blue-color;
  }

  path {
    transition: all 0.14s ease-in-out;
  }
}

.contacts {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
}

.contactsDesc {
  max-width: 450px;
  font-size: 18px;
  line-height: 32px;
  margin-top: 6px;
}

.contactLinks {
  font-size: 18px;
  line-height: 32px;
  margin: 6px 15px 0;
}

.contactLink {
  display: flex;
  align-items: center;
  color: #fff;

  &:hover {
    color: $secondary-blue-color;

    svg {
      animation: bounce 0.6s ease-in-out;

      path {
        fill: $secondary-blue-color;
      }
    }
  }

  svg {
    display: inline-block;
    margin-right: 10px;
  }
}

.copyright {
  color: rgba(255, 255, 255, 0.5);
  padding: 0 28px;
  margin-top: 100px;
}

@media only screen and (max-width: 768px) {
  .contacts {
    display: block;
  }

  .social {
    justify-content: flex-end;
  }

  .blueLine2 {
    display: flex;
    align-items: flex-end;
    padding-bottom: 19px;
  }

  .contactsDesc {
    max-width: none;
    margin: 0;
  }

  .contactLinks {
    margin: 40px 0 45px;
  }
}

@media only screen and (max-width: 400px) {
  .contactsWrap {
    margin-top: 100px;
  }

  .btn {
    font-size: 18px;
    padding-left: 48px;
    padding-right: 48px;
  }

  .copyright {
    margin-top: 60px;
  }
}
