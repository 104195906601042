.caption {
  font-family: "Vollkorn";
  font-weight: normal;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0 28px;
  margin: 0;
}

@media only screen and (max-width: 768px){
  .caption {
    font-size: 36px;
    line-height: 36px;
  }
}
