@import 'styles/variables.scss';

@keyframes changeColor {
  0% {color: $main-black-color;}
  10% {color: $main-blue-color;}
  15% {color: $main-blue-color;}
  25% {color: $main-black-color;}
  100% {color: $main-black-color;}
}

.popularProducts {
  padding: 90px 0;
}

.productWrap {
  display: grid;
  grid-template-columns: minmax(28px, 1fr) minmax(auto, 1200px) minmax(28px, 1fr);
  margin-top: 140px;

  &:nth-child(2) {
    margin-top: 80px;
  }
}

.blueLine {
  padding-top: 14px;
}

.blueLineItem {
  width: 100%;
  height: 3px;
  background-color: $main-blue-color;
}

.productTitle {
  display: inline-block;
  font-size: 26px;
  line-height: 31px;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 12px 70px;
}

.contentWrap {
  display: flex;
  align-items: flex-start;
}

.productImage {
  width: 240px;
  margin-right: 64px;
}

.productDesc {
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  margin: 0;
}

.featuresWrap {
  display: flex;
  align-items: center;
}

.feature {
  display: flex;
  align-items: center;
  width: 50%;
  margin-top: 45px;

  &:first-child {
    margin-right: 10px;
  }
}

.featureImage {
  width: 65px;
  min-width: 65px;
  height: 65px;
  min-height: 65px;
  margin-right: 10px;
}

.featureDesc {
  font-size: 17px;
  line-height: 28px;
  animation: changeColor 10s linear infinite;
  margin: 0;
}

.downloadBtn {
  display: inline-flex;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.4px;
  color: #fff;
  background-color: $main-blue-color;
  box-shadow: 4px 4px 19px rgba(0, 209, 255, 0.27);
  border-radius: 2px;
  border: none;
  transition: all 0.2s ease-in-out;
  padding: 18px 30px 19px;
  margin-top: 60px;
  cursor: pointer;

  &:hover {
    background-color: $secondary-blue-color;

    svg {
      transform: scale(1.2);
    }
  }

  svg {
    margin-right: 22px;
    transition: all 0.2s ease-in-out;
    transform-origin: center;
  }
}

@media only screen and (max-width: 1080px) {
  .productImage {
    width: 220px;
    margin-right: 38px;
  }

  .featureDesc {
    font-size: 15px;
    line-height: 21px;
  }
}

@media only screen and (max-width: 800px) {
  .contentWrap {
    display: block;
  }

  .productTitle {
    margin: 0 12px 0;
  }

  .productImage {
    display: block;
    width: 200px;
    margin: 30px auto;
  }

  .downloadBtnWrap {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .productTitle {
    font-size: 20px;
    line-height: 28px;
  }

  .productDesc {
    margin-bottom: 30px;
  }

  .featuresWrap {
    display: block;
  }

  .feature {
    width: auto;
    margin: 10px 0 0;

    &:first-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 370px) {
  .downloadBtn {
    font-size: 16px;
  }
}
