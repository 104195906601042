@import-normalize; /* normalize.css */
@import './styles/fonts/fonts.css';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  min-height: 100%;
  height: 100%;
}

body {
  min-width: 320px;
  font-family: 'SF-Pro-Text';
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: #2A2D31;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

textarea {
  resize: none;
}

input,
textarea {
  outline: none;
  box-shadow: none;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
}

button:focus {
  outline: none;
}
