@font-face{
  font-family: 'SF-Pro-Text';
  src: url('./SF-Pro-Text-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'SF-Pro-Text';
  src: url('./SF-Pro-Text-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face{
  font-family: 'Vollkorn';
  src: url('./Vollkorn-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: 'Vollkorn';
  src: url('./Vollkorn-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
