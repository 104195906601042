@import 'styles/variables.scss';

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes increaseWidth {
  0% { transform: scale3d(0, 1, 1); }
  100% { transform: scale3d(1, 1, 1); }
}

@keyframes scrollDown {
  0% { transform: translate(0, -100%); }
  10% { transform: translate(0, 0%); }
  90% { transform: translate(0, 0%); }
  100% { transform: translate(0, 100%); }
}

.intro {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 740px;
  color: #fff;
  text-align: center;
  background-image: url("./img/bg-intro.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 154px;
}

.title {
  font-family: "Vollkorn";
  font-size: 58px;
  line-height: 64px;
  font-weight: normal;
  margin: 0 28px 40px;

  span {
    display: block;
    animation: fadeIn 0.8s ease-in-out 1s both;
  }
}

.desc {
  display: flex;
  align-items: center;
}

.descItem {
  font-size: 25px;
  text-transform: uppercase;
}

.descItem1 {
  span {
    animation: fadeIn 0.8s ease-in-out 2s both;
  }
}

.descItem2 {
  span {
    animation: fadeIn 0.8s ease-in-out 2.6s both;
  }
}

.descItem3 {
  span {
    animation: fadeIn 0.8s ease-in-out 3.2s both;
  }
}

.descSeparator {
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 20px;
  animation: fadeIn 0.5s ease-in-out 3.7s both;
}

.btnWrap {
  animation: fadeIn 0.6s ease-in-out 4.2s both;
}

.btn {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #fff;
  background-color: $main-blue-color;
  box-shadow: 4px 4px 19px rgba(0, 209, 255, 0.27);
  border-radius: 2px;
  border: none;
  transition: background-color 0.2s ease-in-out;
  padding: 30px 66px 29px;
  margin-top: 92px;
  cursor: pointer;

  &:hover {
    background-color: $secondary-blue-color;
  }
}

.bottom {
  display: grid;
  grid-template-columns: minmax(50px, 1fr) minmax(auto, 1100px) minmax(50px, 1fr);
  width: 100%;
  margin-top: 80px;
}

.blueLine {
  display: flex;
  align-items: center;
}

.blueLineItem {
  width: 100%;
  height: 3px;
  background-color: $main-blue-color;
  animation: increaseWidth 0.6s ease-in-out 4.6s both;
  transform-origin: left;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ridgepole {
  width: 100px;
}

.social {
  display: flex;
}

.socialLink {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 10px;

  &:first-child {
    animation: fadeIn 0.5s ease-in-out 5.2s both;
  }

  &:last-child {
    animation: fadeIn 0.5s ease-in-out 5.5s both;
  }
}

.socialIcon {
  display: block;

  &:hover path {
    fill: $secondary-blue-color;
  }

  path {
    transition: all 0.14s ease-in-out;
  }
}

.scrollDownWrap {
  animation: fadeIn 0.5s ease-in-out 5.8s both;
}

.scrollDown {
  transition: all 0.24s ease-in-out;
}

.scrollDownIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 25px;
  margin: 0 auto 18px;
  cursor: pointer;
}

.scrollDownWheel {
  position: relative;
  width: 1px;
  height: 14px;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    animation: scrollDown 3.5s ease-in-out infinite;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    animation: scrollDown 3.5s ease-in-out 0.2s infinite;
  }
}

.scrollDownText {
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .intro {
    background-attachment: inherit;
  }
}

@media only screen and (max-width: 700px) {
  .title {
    font-size: 44px;
    line-height: 50px;
  }

  .descItem {
    font-size: 20px;
  }

  .descSeparator {
    margin: 0 10px;
  }

  .btn {
    font-size: 22px;
    line-height: 26px;
    padding: 26px 56px 25px;
  }
}

@media only screen and (max-width: 500px) {
  .intro {
    min-height: 780px;
    padding-top: 60px;
  }

  .title {
    font-size: 38px;
    line-height: 44px;
  }

  .desc {
    flex-direction: column;
  }

  .descSeparator {
    margin: 18px 0;
  }

  .btn {
    font-size: 20px;
    line-height: 24px;
    padding: 24px 40px 23px;
  }

  .bottom {
    margin-top: 50px;
  }

  .scrollDownWrap {
    display: none;
  }
}

@media only screen and (max-height: 800px) and (max-width: 400px) {
  .intro {
    min-height: 690px;
  }

  .btn {
    margin-top: 60px;
  }
}

@media only screen and (max-height: 660px) and (max-width: 400px) {
  .intro {
    min-height: 580px;
  }

  .title {
    font-size: 28px;
    line-height: 32px;
  }

  .descItem {
    font-size: 16px;
    line-height: 16px;
  }

  .descSeparator {
    margin: 10px;
  }

  .bottom {
    margin-top: 40px;
  }
}
