@keyframes moveDot1 {
  0% { transform: translate(30px, 61px); }
  35% { transform: translate(30px, 61px); }
  40% { transform: translate(1px, 61px); }
  50% { transform: translate(1px, 0px); }
  55% { transform: translate(31px, 0px); }
  56% { transform: translate(31px, 2px); }
  100% { transform: translate(31px, 2px); }
}

@keyframes moveDot2 {
  0% { transform: translate(0px, 0px); }
  34% { transform: translate(0px, 0px); }
  35% { transform: translate(4px, 0px); }
  40% { transform: translate(4px, 31px); }
  44% { transform: translate(-12px, 31px); }
  100% { transform: translate(-12px, 31px); }
}

@keyframes moveDot3 {
  0% { transform: translate(1px, 30px); }
  34% { transform: translate(1px, 30px); }
  35% { transform: translate(0, 29px); }
  40% { transform: translate(0, 0); }
  50% { transform: translate(61px, 0); }
  60% { transform: translate(61px, 60px); }
  65% { transform: translate(31px, 60px); }
  66% { transform: translate(31px, 55px); }
  100% { transform: translate(31px, 55px); }
}

@keyframes moveDot4 {
  0% { transform: translate(31px, 59px); }
  34% { transform: translate(31px, 59px); }
  35% { transform: translate(31px, 61px); }
  40% { transform: translate(0, 61px); }
  50% { transform: translate(0, 0); }
  60% { transform: translate(60px, 0); }
  65% { transform: translate(60px, 30px); }
  100% { transform: translate(60px, 30px); }
}

@keyframes show {
  0% { transform: translate(0, 30px); opacity: 0;}
  100% { transform: translate(0, 0px); opacity: 1;}
}

.whyUs {
  max-width: 1256px;
  padding: 90px 0;
  margin: 0 auto;
}

.caption {
  opacity: 0;
}

.desc {
  max-width: 774px;
  font-size: 18px;
  line-height: 30px;
  opacity: 0;
  margin: 60px auto 64px;
}

.categories {
  display: flex;
  justify-content: space-between;
  opacity: 0;
}

.categorie {
  width: 100%;
  max-width: 330px;

  &:not(:last-child) {
    margin-right: 28px;
  }

  &:nth-child(1) {
    .categorieImageWrap {
      &:after {
        animation: moveDot1 10s linear alternate-reverse infinite;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 32px;
        left: 58px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #33ccfb;
        animation: moveDot2 10s linear alternate infinite;
      }
    }
  }

  &:nth-child(2) {
    .categorieImageWrap {
      &:after {
        animation: moveDot3 10s linear 3.33s alternate infinite both;
      }
    }
  }

  &:nth-child(3) {
    .categorieImageWrap {
      &:after {
        animation: moveDot4 10s linear 6.66s alternate-reverse infinite both;
      }
    }
  }

  svg {
    display: block;
    width: 70px;
    height: 70px;
    margin: 0 auto;
  }
}

.categorieImageWrap {
  position: relative;
  width: 70px;
  height: 70px;
  margin: 0 auto;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #33ccfb;
  }
}

.categorieCaption {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 280px;
  min-height: 84px;
  font-size: 22px;
  line-height: 28px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  margin: 20px auto 25px;
}

.categorieDesc {
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}

.show {
  animation: show 0.8s ease-in-out both;
}

@media only screen and (max-width: 900px){
  .categories {
    display: block;
  }

  .categorie {
    max-width: none;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 60px;
    }
  }

  .categorieCaption {
    max-width: none;
    padding: 0 28px;
    margin: 8px auto;
  }
}

@media only screen and (max-width: 768px){
  .desc {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin: 30px auto 54px;
  }
}
