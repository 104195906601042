@import 'styles/variables.scss';

@keyframes rotateArrow {
  0% {transform: translate(0, 0px) rotate3d(0, 1, 0, 0deg);}
  40% {transform: translate(0, 0px) rotate3d(0, 1, 0, 360deg);}
  100% {transform: translate(0, 0px) rotate3d(0, 1, 0, 360deg);}
}

.ourProducts {
  background-image: url("./img/bg-our-products.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
  overflow: hidden;
  padding: 90px 0;
}

.categories {
  display: flex;
  justify-content: space-between;
}

.categorie {
  width: 100%;

  &:not(:last-child) {
    margin-right: 28px;
  }
}

.categorieCaption {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: normal;
  margin: 90px 0 40px;
}

.categorieDesc {
  span {
    display: block;
    font-size: 16px;
    line-height: 28px;
  }
}

.downloadFullBtn {
  display: inline-flex;
  align-items: center;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #fff;
  background-color: $main-blue-color;
  box-shadow: 4px 4px 19px rgba(0, 209, 255, 0.27);
  border-radius: 2px;
  border: none;
  transition: all 0.2s ease-in-out;
  padding: 24px 34px 23px;
  margin: 90px auto;
  cursor: pointer;

  &:hover {
    background-color: $secondary-blue-color;

    svg {
      transform: scale(1.2);
    }
  }

  svg {
    height: 26px;
    width: 26px;
    margin-right: 22px;
    transform-origin: center;
    transition: all 0.2s ease-in-out;
  }
}

.seeMore {
  font-size: 15px;
  line-height: 20px;
  text-align: center;

  & > div {
    margin-bottom: 28px;
  }

  & > svg {
    display: inline-block;
    animation: rotateArrow 3.5s ease-in-out infinite;
  }
}

@media only screen and (max-width: 900px) {
  .categories {
    flex-wrap: wrap;
  }

  .categorie {
    width: 50%;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .categorieCaption {
    min-height: auto;
    font-size: 20px;
    line-height: 26px;
    margin: 50px 0 20px;
  }

  .categorieDesc {
    span {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .downloadFullBtn {
    font-size: 16px;
    line-height: 24px;
    padding: 15px 20px 14px;

    svg {
      margin-right: 10px;
      width: 25px;
      height: 22px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .categorie {
    width: 100%;
  }
}
